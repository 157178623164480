import React from "react"
import LayoutNew from "../../components/LayoutNew"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

export default function TermsOfUse() {
  return (
    <LayoutNew>
      <Helmet>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <title>90 Days Since Launching Purple Cow TV  💜🐄</title>
      </Helmet>
      <section className="px-4 py-8 mx-auto font-light prose prose-lg">
        <h1>90 Days Since Launching Purple Cow TV</h1>
        
          <StaticImage
          layout="constrained"
          src="images/Purple-Cow-TV.png"
          alt="90 Days Since Launching Purple Cow TV"
          /> 


       <p>It's an exhilarating time for all of us at Purple Cow Internet. Our journey began as an ambitious effort to revolutionize internet services in Canada, especially in the beautiful provinces of Nova Scotia, Prince Edward Island, and Newfoundland. And guess what? Not only are we one of the fastest-growing internet service providers in Canada, but we also proudly stand as the highest-rated one based on Google reviews!</p>

       <p>As a customer-centric company, our North Star has always been delivering value. We not only provide the most cost-effective services, but our primary focus has always been on the quality of service we provide and the happiness of our customers. That's why when we ventured into offering TV services, the excitement was palpable both internally and among our loyal customers.</p>

       <p>So, how has our foray into TV services been these past 90 days?</p>

       <strong> <p>Unprecedented Growth and Stellar Reviews</p></strong> 

       <p>The introduction of Purple Cow TV has been nothing short of a grand success. This new offering has allowed even more people to experience the unmatched quality of our internet services. The ripple effect has been an impressive surge in our customer base. What's more? The commendations and positive feedback on our TV service are flooding in. It seems we have struck the right chord yet again!</p>

       <strong> <p>**Why is Purple Cow TV Special?**</p></strong> 

       <p>1. <strong> No More TV Boxes: </strong> In this age of smart devices, we understand that another piece of equipment can be a hassle. That’s why our TV service is app-based, ensuring you can simply download it on a plethora of smart devices you already own. So, no more juggling remote controls or managing space for another gadget!</p>

       <p>2. <strong> Channel Bonanza:</strong>  While other providers might hold back on your favorite channels, Purple Cow TV offers a robust line-up, including favorites like HGTV and Food Network. We believe in comprehensive entertainment without compromises.</p>

       <p>3.<strong>  User's Preferred Choice – The Firestick:</strong>  Many of our users have taken to using the Firestick to enjoy our TV services. It's convenient, easy to use, and offers an excellent viewing experience.</p>

       <p>4. <strong> Pricing That Astounds:</strong>  Perhaps, one of the most talked-about aspects of our TV service is its unbeatable price. At just $20 a month, it's a deal that's hard to resist, and as always, remains a testament to our commitment to offering top-notch services at prices that won’t break the bank.</p>

       <p>In conclusion, the past three months have been phenomenal. Our journey into the world of TV services has been enriching, and the feedback from our community has been the cherry on top. At Purple Cow Internet, we remain dedicated to our mission of redefining internet and TV experiences while keeping costs low and satisfaction high. Here's to more innovations and happier customers! 🥂</p>
         <p>
            Bradley Farquhar<br></br>
            CEO<br></br>
            <br></br><br></br>
        </p>

      </section>
    </LayoutNew>
  )
}
